export const initializeGA = () => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function(){window.dataLayer.push(arguments);};
  window.gtag('js', new Date());
  window.gtag('config', 'G-VEZJHEWKFS'); // Replace with your GA4 ID
};

export const trackPageView = (page) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_path: '/' + page
    });
  }
};
