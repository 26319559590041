import React from 'react';

const NotFoundPage = ({ onNavigate }) => {
  React.useEffect(() => {
    document.title = "Page Not Found | Land and Sea Electronics";
  }, []);

  return (
    <main className="min-h-screen bg-white flex items-center">
      <div className="max-w-7xl mx-auto px-4 py-16 text-center">
        <h1 className="text-6xl font-bold mb-4" style={{ color: '#003255' }}>
          404
        </h1>
        <h2 className="text-2xl mb-8" style={{ color: '#003255' }}>
          Page Not Found
        </h2>
        <p className="mb-8 text-gray-600">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <button
          onClick={() => onNavigate('home')}
          className="px-6 py-3 bg-[#003255] text-white rounded hover:opacity-90 transition-opacity"
        >
          Return Home
        </button>
      </div>
    </main>
  );
};

export default NotFoundPage;
