import React, { useState } from 'react';
import { ChevronDown, ArrowRight } from 'lucide-react';
import { trackPageView } from './utils/analytics';
import NotFoundPage from './components/NotFoundPage';

const customStyles = {
  primary: '#003255',
  lightBg: 'rgba(0, 50, 85, 0.1)',
  hoverBg: 'rgba(0, 50, 85, 0.8)',
  borderColor: 'rgba(0, 50, 85, 0.2)',
};

// Navigation Component
const Navigation = ({ onNavigate }) => {  // Remove unused currentPage prop
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleServiceClick = (path) => {
    setOpenDropdowns({});
    setMobileMenuOpen(false);
    onNavigate(path);
  };

  const toggleDropdown = (title) => {
    setOpenDropdowns(prev => ({
      ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
      [title]: !prev[title]
    }));
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setOpenDropdowns({});
  };

  const menuItems = [
    {
      title: 'HOME',
      path: 'home'
    },
    {
      title: 'SERVICES',
      items: [
        { name: 'Audio/Video Systems', path: 'audio-video' },
        { name: 'Network & Connectivity', path: 'network' },
        { name: 'Entertainment Systems', path: 'entertainment' },
        { name: 'Security Systems', path: 'security' },
        { name: 'Blinds & Shades', path: 'blinds' },
        { name: 'Lighting Control', path: 'lighting' }
      ]
    },
    {
      title: 'SOLUTIONS',
      items: [
        { name: 'AV Services', path: 'av-services' },  // Move to top
        { name: 'Do It Yourself', path: 'diy' },
        { name: 'Residential', path: 'residential' },
        { name: 'Full Vessel Refit', path: 'refit' },
        { name: 'Commercial AV', path: 'commercial-av' }
      ]
    },
    {
      title: 'PORTFOLIO',
      path: 'portfolio'
    },
    {
      title: 'SPECIFICATIONS',
      path: 'specifications'
    },
    {
      title: 'CONSULTATION',
      path: 'consultation'
    }
  ];

  return (
    <nav className="bg-white border-b relative z-50" style={{ borderColor: customStyles.borderColor }}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Mobile menu button and phone number */}
          <div className="flex items-center justify-between w-full md:hidden px-2">
            <button
              onClick={toggleMobileMenu}
              className="p-2 rounded-md"
              style={{ color: customStyles.primary }}
            >
              {mobileMenuOpen ? (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
            
            <a 
              href="tel:+18662880028" 
              className="text-sm font-medium hover:opacity-80"
              style={{ color: customStyles.primary }}
            >
              +1 866-288-0028
            </a>
          </div>

          {/* Desktop menu */}
          <div className="hidden md:flex space-x-8 justify-center w-full">
            {/* Add phone number */}
            <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
              <a 
                href="tel:+18662880028" 
                className="text-sm font-medium hover:opacity-80"
                style={{ color: customStyles.primary }}
              >
                +1 866-288-0028
              </a>
            </div>

            {/* Existing menu items */}
            {menuItems.map((item, index) => (
              <div key={index} className="relative inline-flex items-center">
                {item.path ? (
                  <button
                    onClick={() => onNavigate(item.path)}
                    className="inline-flex items-center px-1 pt-1 text-sm font-medium hover:opacity-80"
                    style={{ color: customStyles.primary }}
                  >
                    {item.title}
                  </button>
                ) : (
                  <div>
                    <button
                      onClick={() => toggleDropdown(item.title)}
                      className="inline-flex items-center px-1 pt-1 text-sm font-medium hover:opacity-80"
                      style={{ color: customStyles.primary }}
                    >
                      {item.title}
                      <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${openDropdowns[item.title] ? 'rotate-180' : ''}`} />
                    </button>
                    
                    {item.items && openDropdowns[item.title] && (
                      <div className="absolute top-full left-0 w-56 mt-1 bg-white border rounded-md shadow-lg z-50"
                           style={{ borderColor: customStyles.borderColor }}>
                        <div className="py-1">
                          {item.items.map((subItem, subIndex) => (
                            <button
                              key={subIndex}
                              onClick={() => handleServiceClick(subItem.path)}
                              className="block w-full text-left px-4 py-2 text-sm hover:opacity-80"
                              style={{ color: customStyles.primary }}
                            >
                              {subItem.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Mobile menu overlay */}
          <div className={`
            fixed inset-0 bg-white transform transition-transform duration-300 ease-in-out md:hidden
            ${mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          `}>
            <div className="pt-20 px-4 space-y-2">
              {menuItems.map((item, index) => (
                <div key={index} className="py-2">
                  {item.path ? (
                    <button
                      onClick={() => handleServiceClick(item.path)}
                      className="w-full text-left py-2 text-lg font-medium"
                      style={{ color: customStyles.primary }}
                    >
                      {item.title}
                    </button>
                  ) : (
                    <div>
                      <button
                        onClick={() => toggleDropdown(item.title)}
                        className="w-full text-left py-2 text-lg font-medium flex justify-between items-center"
                        style={{ color: customStyles.primary }}
                      >
                        {item.title}
                        <ChevronDown className={`h-5 w-5 transition-transform ${openDropdowns[item.title] ? 'rotate-180' : ''}`} />
                      </button>
                      
                      {item.items && openDropdowns[item.title] && (
                        <div className="pl-4 py-2 space-y-2">
                          {item.items.map((subItem, subIndex) => (
                            <button
                              key={subIndex}
                              onClick={() => handleServiceClick(subItem.path)}
                              className="block w-full text-left py-2 text-base"
                              style={{ color: customStyles.primary }}
                            >
                              {subItem.name}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

// Services Section Component
const ServicesSection = ({ onNavigate }) => {
  const services = [
    {
      title: "AV Services",
      description: "Professional audio-visual maintenance, support, and repair services. We offer comprehensive service plans to ensure your systems perform optimally with 24/7 technical support and regular maintenance.",
      path: "av-services"
    },
    {
      title: "Do It Yourself Installation",
      description: "Perfect for hands-on owners. We provide the hardware, software, detailed plans, and technical support while you handle the cable pulls and physical installation to save on labor costs.",
      path: "diy"
    },
    {
      title: "Residential Solutions",
      description: "Comprehensive smart home technology integration including automation, entertainment systems, and security solutions for luxury homes. Experience the same level of excellence we deliver on vessels.",
      path: "residential"
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 md:py-16">
      <div className="grid gap-4 md:gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6 md:p-8 text-center">
              <h3 className="text-xl md:text-2xl font-bold mb-4" style={{ color: customStyles.primary }}>{service.title}</h3>
              <p className="text-sm md:text-base mb-4" style={{ color: customStyles.primary }}>{service.description}</p>
              <button 
                onClick={() => onNavigate(service.path)}
                className="inline-flex items-center justify-center hover:opacity-80 text-sm md:text-base"
                style={{ color: customStyles.primary }}
              >
                Learn more <ArrowRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Home Page Component
const HomePage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Marine Technology Solutions | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="relative h-[50vh] md:h-[60vh]">
          <div className="absolute inset-0">
            <div className="h-full w-full" style={{ backgroundColor: customStyles.primary }}>
              <img 
                src="/images/homehero.jpg"
                alt="Luxury yacht interior showcasing high-end electronics"
                className="w-full h-full object-cover opacity-60"  // Reduced opacity to darken image
                onError={(e) => {
                  e.target.src = '/api/placeholder/1920/600';
                  e.target.alt = 'Placeholder image';
                }}
              />
              {/* Add dark overlay */}
              <div className="absolute inset-0 bg-black opacity-40"></div>
            </div>
          </div>

          <div className="relative h-full flex items-center justify-center">
            <div className="max-w-7xl mx-auto px-4 w-full">
              <div className="text-white max-w-2xl mx-auto text-center">
                <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-3 md:mb-6 text-shadow-lg">
                  Land And Sea Electronics
                </h1>
                <p className="text-base sm:text-lg md:text-xl mb-6 md:mb-8 text-shadow">
                  Delivering exceptional audio, video, networking, blinds & shades, lighting control, and security solutions for luxury vessels, residential properties, and commercial spaces
                </p>
                <div>
                  <button 
                    onClick={() => onNavigate('consultation')}
                    className="bg-white bg-opacity-90 border-2 px-6 py-3 rounded text-sm sm:text-base hover:bg-opacity-100 transition-colors"
                    style={{ 
                      borderColor: customStyles.primary,
                      color: customStyles.primary,
                    }}
                  >
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        
        <section aria-label="Services Overview">
          <ServicesSection onNavigate={onNavigate} />
        </section>
      </article>
    </main>
  );
};

// Network Page Component
const NetworkPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Network & Connectivity Solutions | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Network & Connectivity Solutions</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Enterprise-grade networking solutions for marine environments</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>High-Speed Internet</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>VSAT satellite connectivity</li>
                <li>4G/5G cellular backup</li>
                <li>Global coverage options</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Network Infrastructure</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Marine-grade switches</li>
                <li>Enterprise WiFi 6 systems</li>
                <li>Redundant power systems</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Security Features</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Advanced firewall protection</li>
                <li>VPN access</li>
                <li>Network segmentation</li>
              </ul>
            </div>
          </div>

          {/* New: Detailed Information Sections */}
          <div className="space-y-16">
            {/* Technology Overview */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Marine Network Technology
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Multi-Layer Connectivity
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our marine network solutions utilize multiple connectivity technologies to ensure you're always connected:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Primary VSAT satellite connection with guaranteed bandwidth</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>4G/5G cellular backup systems with automatic failover</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Local WiFi integration for marina and port connectivity</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Enterprise-Grade Hardware
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    We exclusively use marine-certified networking equipment designed for reliability in harsh environments:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Corrosion-resistant components and enclosures</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Redundant power supplies and UPS systems</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Industrial-grade switches and access points</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Advanced Features */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Advanced Network Features
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 rounded-lg shadow-lg" style={{ backgroundColor: customStyles.lightBg }}>
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Network Security</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Enterprise-grade firewall protection</li>
                    <li>VPN access for remote management</li>
                    <li>Network segmentation for guest access</li>
                    <li>Regular security updates and monitoring</li>
                  </ul>
                </div>
                <div className="p-6 rounded-lg shadow-lg" style={{ backgroundColor: customStyles.lightBg }}>
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Performance Optimization</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Quality of Service (QoS) configuration</li>
                    <li>Bandwidth management</li>
                    <li>Traffic prioritization</li>
                    <li>Content caching</li>
                  </ul>
                </div>
                <div className="p-6 rounded-lg shadow-lg" style={{ backgroundColor: customStyles.lightBg }}>
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Management Tools</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Remote monitoring and diagnostics</li>
                    <li>Usage analytics and reporting</li>
                    <li>Automated backup systems</li>
                    <li>24/7 technical support</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Support & Maintenance */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Support & Maintenance
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>24/7 Technical Support</h3>
                  <p className="text-gray-700">
                    Our dedicated support team is available around the clock to ensure your network performs optimally:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Remote troubleshooting and diagnostics</li>
                    <li>• Emergency response protocols</li>
                    <li>• Regular maintenance schedules</li>
                    <li>• Performance optimization</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Proactive Monitoring</h3>
                  <p className="text-gray-700">
                    We continuously monitor your network to prevent issues before they occur:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Real-time performance monitoring</li>
                    <li>• Automated alerts and notifications</li>
                    <li>• Regular security updates</li>
                    <li>• Bandwidth usage analysis</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// After NetworkPage Component
const AudioVideoPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Audio/Video Systems | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Audio/Video Systems</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Premium marine-grade audio and video solutions</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Existing features grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Audio Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Multi-zone audio distribution</li>
                <li>Marine-grade speakers</li>
                <li>Custom sound calibration</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Video Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>4K/8K Display integration</li>
                <li>Video distribution systems</li>
                <li>Custom theater rooms</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Control Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Integrated control interfaces</li>
                <li>Mobile device control</li>
                <li>Custom automation</li>
              </ul>
            </div>
          </div>

          {/* New: Detailed Information Sections */}
          <div className="space-y-16">
            {/* Premium Audio Solutions */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Premium Audio Solutions
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Multi-Zone Audio Experience
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Create the perfect atmosphere in every area of your vessel with our customized audio zoning:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Independent volume and source control for each zone</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Seamless audio distribution throughout the vessel</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Party mode for synchronized playback</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Marine-Grade Components
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    All audio components are specifically selected for marine environments:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Corrosion-resistant speakers and enclosures</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Weather-sealed outdoor audio systems</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>High-performance amplifiers with thermal protection</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Video Systems */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Advanced Video Systems
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>4K/8K Displays</h3>
                  <ul className="space-y-2 text-gray-700 text-left">
                    <li>• HDR-compatible screens</li>
                    <li>• Anti-glare technology</li>
                    <li>• Marine-certified mounts</li>
                    <li>• Brightness optimization</li>
                  </ul>
                </div>
                <div className="text-center p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Distribution</h3>
                  <ul className="space-y-2 text-gray-700 text-left">
                    <li>• Matrix switching</li>
                    <li>• HDMI over IP</li>
                    <li>• Wireless casting</li>
                    <li>• 4K video routing</li>
                  </ul>
                </div>
                <div className="text-center p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Content</h3>
                  <ul className="space-y-2 text-gray-700 text-left">
                    <li>• Movie servers</li>
                    <li>• Streaming services</li>
                    <li>• Live TV integration</li>
                    <li>• Content management</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Custom Theater Rooms */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Custom Theater Rooms
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Design & Acoustics</h3>
                  <p className="text-gray-700">
                    Transform any space into an immersive cinema experience:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Acoustic treatment and sound isolation</li>
                    <li>• Custom seating arrangements</li>
                    <li>• Ambient lighting integration</li>
                    <li>• Premium projection systems</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Audio Excellence</h3>
                  <p className="text-gray-700">
                    State-of-the-art sound systems for the ultimate experience:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Dolby Atmos configuration</li>
                    <li>• Professional calibration</li>
                    <li>• Hidden speaker installation</li>
                    <li>• Subwoofer optimization</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Control & Integration */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Seamless Control & Integration
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 rounded-lg shadow-lg bg-white">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>User Interface</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Intuitive touch panels</li>
                    <li>Mobile device control</li>
                    <li>Voice commands</li>
                    <li>Custom scene creation</li>
                  </ul>
                </div>
                <div className="p-6 rounded-lg shadow-lg bg-white">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Automation</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Time-based events</li>
                    <li>Motion activation</li>
                    <li>Environmental triggers</li>
                    <li>Scene coordination</li>
                  </ul>
                </div>
                <div className="p-6 rounded-lg shadow-lg bg-white">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>System Integration</h3>
                  <ul className="space-y-3 text-gray-700">
                    <li>Lighting synchronization</li>
                    <li>Climate control</li>
                    <li>Shade automation</li>
                    <li>Security integration</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          {/* Enhanced Call to Action */}
          <div className="text-center mt-16">
            <h3 className="text-2xl font-bold mb-4" style={{ color: customStyles.primary }}>
              Ready to Elevate Your Entertainment Experience?
            </h3>
            <p className="text-gray-700 mb-8 max-w-2xl mx-auto">
              Let's discuss how we can create the perfect audio/video solution for your vessel.
            </p>
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule AV Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// After AudioVideoPage Component
const EntertainmentPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Entertainment Systems | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Entertainment Systems</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Custom entertainment solutions for luxury vessels</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Theater Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Custom theater design</li>
                <li>4K/8K projection systems</li>
                <li>Dolby Atmos audio</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Streaming & Media</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Kaleidescape movie servers</li>
                <li>Plex media systems</li>
                <li>Global streaming access</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Outdoor Entertainment</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Weather-resistant displays</li>
                <li>Marine-grade sound systems</li>
                <li>Automated shade control</li>
              </ul>
            </div>
          </div>

          {/* New: Detailed Information Sections */}
          <div className="space-y-16">
            {/* Media Integration */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Complete Media Integration
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Content Management
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Access your entire media library from anywhere on your vessel:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Centralized movie and music servers</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>High-capacity storage solutions</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Multi-room synchronized playback</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Streaming Services
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Global access to your favorite streaming platforms:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Netflix, Amazon Prime, and more</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Sports and live event streaming</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Content caching for offline viewing</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Theater Experience */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Luxury Theater Experience
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Visual Excellence</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• 4K/8K HDR projectors</li>
                    <li>• Motorized screens</li>
                    <li>• Ambient light control</li>
                    <li>• Custom screen masking</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Immersive Audio</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Dolby Atmos systems</li>
                    <li>• Acoustic treatment</li>
                    <li>• Bass management</li>
                    <li>• Sound isolation</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Comfort</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Custom seating</li>
                    <li>• Climate control</li>
                    <li>• Ambient lighting</li>
                    <li>• Integrated control systems</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Outdoor Entertainment */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Outdoor Entertainment
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Weather-Resistant Solutions
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Enjoy your favorite media in any weather condition:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Outdoor-rated displays and speakers</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Waterproof enclosures</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>UV-resistant materials</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Seamless Integration
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Control your outdoor entertainment system with ease:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Unified control interfaces</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Mobile device compatibility</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Voice control options</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// Update App component
// After EntertainmentPage Component
const SecurityPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Security Systems | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Security Systems</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Advanced security solutions for complete vessel protection</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Surveillance Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>4K Axis cameras</li>
                <li>Night vision capability</li>
                <li>360° coverage</li>
                <li>Video analytics</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Access Control</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Biometric authentication</li>
                <li>Smart card systems</li>
                <li>Remote access management</li>
                <li>Guest credentials</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Monitoring Services</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>24/7 remote monitoring</li>
                <li>Mobile alerts</li>
                <li>Intrusion detection</li>
                <li>Environmental sensors</li>
              </ul>
            </div>
          </div>

          {/* Detailed Information Sections */}
          <div className="space-y-16">
            {/* System Overview */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Marine Security Technology
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Comprehensive Coverage
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our security solutions provide complete vessel protection:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Multiple camera views with no blind spots</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Advanced motion detection and tracking</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Integration with vessel navigation systems</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Smart Technology
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Utilizing AI and machine learning for enhanced security:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Facial recognition and person tracking</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Object detection and classification</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Behavioral analysis and alerts</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Access Control */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Access Control Systems
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Authentication</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Fingerprint scanners</li>
                    <li>• Facial recognition</li>
                    <li>• RFID cards</li>
                    <li>• PIN codes</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Management</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• User permissions</li>
                    <li>• Time-based access</li>
                    <li>• Guest management</li>
                    <li>• Access logs</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Integration</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Mobile app control</li>
                    <li>• Remote management</li>
                    <li>• Emergency protocols</li>
                    <li>• Automation rules</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Monitoring Services */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                24/7 Monitoring
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Professional Monitoring
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our monitoring center provides constant surveillance:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Real-time alert verification</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Emergency response coordination</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Monthly security reports</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Mobile Integration
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Stay connected through our mobile platform:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Live camera feeds</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Instant alert notifications</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Remote system control</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Security Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// After SecurityPage Component
const BlindsPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Automated Blinds & Shades | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Automated Blinds & Shades</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Smart shading solutions for luxury marine environments</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Automated Control</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Voice control integration</li>
                <li>Mobile app control</li>
                <li>Scheduled automation</li>
                <li>Scene presets</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Marine Solutions</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Marine-grade materials</li>
                <li>UV protection options</li>
                <li>Custom sizing & fitting</li>
                <li>Weather resistance</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Smart Features</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Sun position tracking</li>
                <li>Temperature optimization</li>
                <li>Scene integration</li>
                <li>Energy efficiency</li>
              </ul>
            </div>
          </div>

          {/* Detailed Information Sections */}
          <div className="space-y-16">
            {/* Smart Control */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Smart Control Technology
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Automated Convenience
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Experience seamless control of your vessel's shading:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Integrated voice control with major platforms</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Intuitive mobile app for remote operation</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Customizable scheduling and automation</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Scene Integration
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Coordinate your shading with other systems:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Entertainment system synchronization</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Climate control integration</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Lighting scene coordination</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Materials & Design */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Marine-Grade Materials
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Durability</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Corrosion-resistant hardware</li>
                    <li>• Salt-water protection</li>
                    <li>• UV-resistant fabrics</li>
                    <li>• Weather-sealed motors</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Performance</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Silent operation</li>
                    <li>• Smooth movement</li>
                    <li>• Precise positioning</li>
                    <li>• Low maintenance</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Design</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Custom colors</li>
                    <li>• Fabric options</li>
                    <li>• Pattern matching</li>
                    <li>• Integrated design</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Smart Features */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Intelligent Features
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Environmental Response</h3>
                  <p className="text-gray-700">
                    Automated adjustment based on environmental conditions:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Sun position tracking</li>
                    <li>• Temperature monitoring</li>
                    <li>• Weather detection</li>
                    <li>• Light level sensing</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Energy Efficiency</h3>
                  <p className="text-gray-700">
                    Optimize your vessel's energy consumption:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Heat gain reduction</li>
                    <li>• Natural light optimization</li>
                    <li>• HVAC load reduction</li>
                    <li>• Energy usage tracking</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Shading Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// After BlindsPage Component
const LightingPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Lighting Control Systems | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Lighting Control Systems</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Intelligent lighting solutions for complete vessel illumination</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Smart Control</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Voice activation</li>
                <li>Mobile app control</li>
                <li>Automated schedules</li>
                <li>Scene programming</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Lighting Solutions</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>LED integration</li>
                <li>Color temperature control</li>
                <li>Dimming systems</li>
                <li>Accent lighting</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Energy Management</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Motion sensors</li>
                <li>Daylight harvesting</li>
                <li>Power monitoring</li>
                <li>Energy optimization</li>
              </ul>
            </div>
          </div>

          {/* Detailed Information Sections */}
          <div className="space-y-16">
            {/* Smart Control Overview */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Intelligent Control Systems
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Advanced Control Options
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Control your vessel's lighting with intuitive interfaces:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Voice commands through popular platforms</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Smartphone and tablet control apps</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Wall-mounted touchscreens</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Automation Capabilities
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Create sophisticated lighting schedules:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Time-based lighting adjustments</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Motion-activated lighting</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Astronomical time clock integration</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Lighting Technology */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Marine Lighting Technology
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>LED Systems</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Energy-efficient LED fixtures</li>
                    <li>• RGB color changing</li>
                    <li>• Tunable white light</li>
                    <li>• Long-life operation</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Dimming</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Smooth dimming curves</li>
                    <li>• Multiple dimming protocols</li>
                    <li>• Scene presets</li>
                    <li>• Flicker-free operation</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Marine Grade</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Corrosion resistance</li>
                    <li>• Water-tight fixtures</li>
                    <li>• Salt air protection</li>
                    <li>• UV resistant</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Energy Management */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Energy Efficiency
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Smart Management</h3>
                  <p className="text-gray-700">
                    Optimize energy consumption through intelligent controls:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Occupancy-based control</li>
                    <li>• Daylight harvesting</li>
                    <li>• Schedule-based dimming</li>
                    <li>• Energy usage reporting</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Environmental Impact</h3>
                  <p className="text-gray-700">
                    Reduce your vessel's environmental footprint:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• LED energy savings</li>
                    <li>• Reduced heat output</li>
                    <li>• Lower maintenance needs</li>
                    <li>• Extended product life</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Integration Features */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                System Integration
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Entertainment Integration</h3>
                  <p className="text-gray-700">
                    Synchronize lighting with your entertainment systems:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Movie scene synchronization</li>
                    <li>• Music visualization</li>
                    <li>• Gaming ambiance</li>
                    <li>• Party modes</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Security Features</h3>
                  <p className="text-gray-700">
                    Enhance vessel security through lighting:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Vacation mode simulation</li>
                    <li>• Motion-triggered alerts</li>
                    <li>• Emergency lighting</li>
                    <li>• Security system integration</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <h3 className="text-2xl font-bold mb-4" style={{ color: customStyles.primary }}>
              Ready to Transform Your Vessel's Lighting?
            </h3>
            <p className="text-gray-700 mb-8 max-w-2xl mx-auto">
              Let's design an intelligent lighting solution that enhances your vessel's ambiance and efficiency.
            </p>
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Lighting Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// Solutions Pages
const CommercialAVPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Commercial AV Solutions | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Commercial AV Solutions</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Professional audio-visual solutions for business environments</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Conference Rooms</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Video conferencing systems</li>
                <li>Wireless presentation</li>
                <li>Interactive displays</li>
                <li>Sound management</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Digital Signage</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Video wall systems</li>
                <li>Content management</li>
                <li>Interactive kiosks</li>
                <li>Real-time information</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Control Systems</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Room scheduling</li>
                <li>Mobile device control</li>
                <li>System monitoring</li>
                <li>Remote management</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Conference Solutions */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Conference Room Technology
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Video Conferencing
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Professional-grade conference room solutions:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>4K PTZ cameras with auto-tracking</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Beamforming microphone arrays</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Echo cancellation and noise reduction</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Presentation Systems
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Wireless content sharing and collaboration tools:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Multi-device screen sharing</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Interactive whiteboarding</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Document annotation and sharing</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Digital Signage */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Digital Signage Solutions
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Display Systems</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Multi-panel video walls</li>
                    <li>• Interactive touch displays</li>
                    <li>• High-brightness screens</li>
                    <li>• Custom mounting solutions</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Content Management</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Cloud-based control</li>
                    <li>• Dynamic content updates</li>
                    <li>• Scheduling system</li>
                    <li>• Multi-location management</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Integration</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Data visualization</li>
                    <li>• Social media feeds</li>
                    <li>• Emergency alerts</li>
                    <li>• Analytics reporting</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Control Solutions */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Control & Automation
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Room Management</h3>
                  <p className="text-gray-700">
                    Streamline meeting space operations:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Room booking displays</li>
                    <li>• Occupancy monitoring</li>
                    <li>• Usage analytics</li>
                    <li>• Calendar integration</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>System Control</h3>
                  <p className="text-gray-700">
                    Unified control interface:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Touch panel controllers</li>
                    <li>• Mobile device access</li>
                    <li>• Voice commands</li>
                    <li>• Remote management</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Implementation Approach */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Implementation Approach
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>1</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Assessment</h3>
                  <p className="text-sm text-gray-600">Evaluate business needs and space requirements</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>2</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Design</h3>
                  <p className="text-sm text-gray-600">Custom solution planning and system architecture</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>3</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Deployment</h3>
                  <p className="text-sm text-gray-600">Professional installation and system configuration</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>4</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Training</h3>
                  <p className="text-sm text-gray-600">Staff training and ongoing support</p>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Commercial AV Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

const DIYPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Do It Yourself Installation | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Do It Yourself Installation</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Professional equipment and support for self-installation</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Equipment Package</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Marine-grade hardware</li>
                <li>Premium components</li>
                <li>Installation supplies</li>
                <li>Testing equipment</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Technical Support</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Detailed documentation</li>
                <li>Video tutorials</li>
                <li>Phone support</li>
                <li>Remote assistance</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Cost Benefits</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Labor cost savings</li>
                <li>Reduced installation time</li>
                <li>Professional-grade results</li>
                <li>Flexible scheduling</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* DIY Package */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                DIY Installation Package
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Comprehensive Kit
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our DIY package includes everything you need for a successful installation:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>High-quality marine-grade hardware</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>All necessary cables and connectors</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Specialized installation tools</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Expert Guidance
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    We provide detailed instructions and support to ensure your installation goes smoothly:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Step-by-step installation guides</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Video tutorials for complex tasks</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Access to our technical support team</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Installation Process */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Implementation Steps
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>1</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Planning</h3>
                  <p className="text-sm text-gray-600">Review guides and plan your installation timeline</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>2</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Setup</h3>
                  <p className="text-sm text-gray-600">Prepare workspace and organize materials</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>3</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Installation</h3>
                  <p className="text-sm text-gray-600">Follow step-by-step installation process</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>4</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Verification</h3>
                  <p className="text-sm text-gray-600">Test and verify all installed systems</p>
                </div>
              </div>
            </section>

            {/* Benefits */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Benefits of DIY Installation
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Cost Savings</h3>
                  <p className="text-gray-700">
                    Save on labor costs and enjoy the satisfaction of completing the installation yourself:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Reduced installation expenses</li>
                    <li>• No need for professional installers</li>
                    <li>• Flexible scheduling</li>
                    <li>• Control over the entire process</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Professional Results</h3>
                  <p className="text-gray-700">
                    Achieve professional-grade results with our high-quality components and expert guidance:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Premium marine-grade hardware</li>
                    <li>• Detailed installation guides</li>
                    <li>• Access to technical support</li>
                    <li>• Confidence in your installation</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Start Your DIY Project
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

const RefitPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Full Vessel Refit | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Full Vessel Refit</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Complete technology integration for luxury vessels</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Planning</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Requirements analysis</li>
                <li>System design</li>
                <li>Timeline planning</li>
                <li>Budget optimization</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Installation</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Professional installation team</li>
                <li>Minimal disruption</li>
                <li>Quality assurance</li>
                <li>System testing</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Support</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Ongoing maintenance</li>
                <li>24/7 technical support</li>
                <li>System upgrades</li>
                <li>Performance optimization</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Refit Process */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Refit Process
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Comprehensive Planning
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our refit process begins with a thorough analysis of your vessel's needs:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Detailed requirements analysis</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Custom system design</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Timeline and budget planning</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Professional Installation
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Our experienced team ensures a seamless installation process:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Expert installation team</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Minimal disruption to your vessel</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Comprehensive system testing</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* System Integration */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                System Integration
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Audio/Video</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Multi-zone audio systems</li>
                    <li>• 4K/8K video distribution</li>
                    <li>• Custom theater rooms</li>
                    <li>• Outdoor entertainment</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Networking</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• High-speed internet</li>
                    <li>• Enterprise WiFi systems</li>
                    <li>• Network security</li>
                    <li>• Remote management</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Control Systems</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Integrated control interfaces</li>
                    <li>• Mobile device control</li>
                    <li>• Custom automation</li>
                    <li>• Scene programming</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Implementation Phases */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Implementation Phases
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>1</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Discovery</h3>
                  <p className="text-sm text-gray-600">Comprehensive vessel assessment and planning</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>2</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Engineering</h3>
                  <p className="text-sm text-gray-600">Detailed system design and documentation</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>3</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Integration</h3>
                  <p className="text-sm text-gray-600">Professional installation and system integration</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>4</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Commissioning</h3>
                  <p className="text-sm text-gray-600">System testing and crew training</p>
                </div>
              </div>
            </section>

            {/* Benefits */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Benefits of a Full Vessel Refit
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Enhanced Performance</h3>
                  <p className="text-gray-700">
                    Upgrade your vessel's technology for improved performance and reliability:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• State-of-the-art systems</li>
                    <li>• Increased efficiency</li>
                    <li>• Enhanced user experience</li>
                    <li>• Future-proof technology</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Comprehensive Support</h3>
                  <p className="text-gray-700">
                    Enjoy peace of mind with our ongoing support and maintenance services:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• 24/7 technical support</li>
                    <li>• Regular system maintenance</li>
                    <li>• Performance optimization</li>
                    <li>• System upgrades</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Refit Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// Portfolio & Specifications Pages
const PortfolioPage = () => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Our Portfolio | Land and Sea Electronics";
  }, []);

  const projects = [
    { 
      name: "Arriva", 
      size: "47m", 
      image: "/images/portfolio/arriva.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Lady Sura", 
      size: "49m", 
      image: "/images/portfolio/ladysura.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Chasing Daylight", 
      size: "50m", 
      image: "/images/portfolio/chasingdaylight.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Lady Kathryn", 
      size: "61m", 
      image: "/images/portfolio/ladykathryn.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Kiss the Sky", 
      size: "52m", 
      image: "/images/portfolio/kissthesky.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Atlantica", 
      size: "29m", 
      image: "/images/portfolio/atlantica.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Summerwind", 
      size: "25m", 
      image: "/images/portfolio/summerwind.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Sirenuse", 
      size: "32m", 
      image: "/images/portfolio/sirenuse.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    },
    { 
      name: "Phoenix", 
      size: "40m", 
      image: "/images/portfolio/phoenix.jpg",  // Update with your actual image path
      description: "Luxury motor yacht" 
    }
  ];

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Our Portfolio</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Experience with world-class luxury vessels</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Featured Projects Grid */}
          <div className="grid md:grid-cols-2 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Project Types</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Motor Yachts</li>
                <li>Sailing Yachts</li>
                <li>Sport Fishing Vessels</li>
                <li>Commercial Ships</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Services</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Complete System Integration</li>
                <li>Technology Upgrades</li>
                <li>New Builds</li>
                <li>Refits</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Project Gallery */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Featured Projects
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {projects.map((project, index) => (
                  <div 
                    key={index}
                    className="overflow-hidden rounded-lg shadow-lg bg-white"
                  >
                    <div className="relative h-64">
                      <img 
                        src={project.image}
                        alt={`${project.name} - ${project.description}`}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.src = '/api/placeholder/800/600';
                          e.target.alt = 'Placeholder yacht image';
                        }}
                      />
                      <div 
                        className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300"
                        style={{ backgroundColor: 'rgba(0, 50, 85, 0.8)' }}
                      >
                        <div className="text-white text-center p-4">
                          <h3 className="text-2xl font-bold mb-2">{project.name}</h3>
                          <p className="text-lg mb-2">{project.size}</p>
                          <p className="text-sm">{project.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 text-center">
                      <h3 className="text-xl font-bold mb-2" style={{ color: customStyles.primary }}>
                        {project.name}
                      </h3>
                      <p style={{ color: customStyles.primary }}>{project.size} - {project.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Project Categories */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Project Categories
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>25+</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Motor Yachts</h3>
                  <p className="text-sm text-gray-600">Complete system integration</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>15+</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Sailing Yachts</h3>
                  <p className="text-sm text-gray-600">Technology upgrades</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>10+</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Sport Fishing</h3>
                  <p className="text-sm text-gray-600">Custom installations</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>5+</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Commercial</h3>
                  <p className="text-sm text-gray-600">Marine solutions</p>
                </div>
              </div>
            </section>
          </div>
        </section>
      </article>
    </main>
  );
};

const SpecificationsPage = () => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Our Technology Partners | Land and Sea Electronics";
  }, []);

  const brands = [
    {
      category: "Control & Automation",
      companies: [
        { name: "Crestron", logo: "/images/specifications/crestron.jpg" }
      ],
      description: "Industry-leading automation and control systems"
    },
    {
      category: "Security & Surveillance",
      companies: [
        { name: "Axis", logo: "/images/specifications/axis.jpg" },
        { name: "Cisco", logo: "/images/specifications/cisco.png" }
      ],
      description: "Enterprise-grade security solutions"
    },
    {
      category: "Networking",
      companies: [
        { name: "Ruckus", logo: "/images/specifications/ruckus.png" },
        { name: "Peplink", logo: "/images/specifications/peplink.png" },
        { name: "Cisco", logo: "/images/specifications/cisco.png" },
        { name: "Starlink", logo: "/images/specifications/starlink.png" }
      ],
      description: "Professional-grade networking equipment"
    },
    {
      category: "Audio",
      companies: [
        { name: "Sonance", logo: "/images/specifications/sonance.jpg" },
        { name: "Denon", logo: "/images/specifications/denon.png" },
        { name: "Yamaha", logo: "/images/specifications/yamaha.webp" }
      ],
      description: "Premium audio solutions"
    },
    {
      category: "Video & Entertainment",
      companies: [
        { name: "Sony", logo: "/images/specifications/sony.png" },
        { name: "Samsung", logo: "/images/specifications/samsung.jpg" },
        { name: "Plex", logo: "/images/specifications/plex.webp" },
        { name: "Kaleidescape", logo: "/images/specifications/kaleidescape.png" }
      ],
      description: "High-end video and entertainment systems"
    },
    {
      category: "Storage & Infrastructure",
      companies: [
        { name: "QNAP", logo: "/images/specifications/qnap.png" }
      ],
      description: "Enterprise storage solutions"
    },
    {
      category: "Environmental Control",
      companies: [
        { name: "Somfy", logo: "/images/specifications/somfy.png" }
      ],
      description: "Automated shade and environmental control"
    }
  ];

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Our Technology Partners</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Partnering with industry leaders to deliver exceptional solutions</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Overview Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Premium Brands</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Industry leaders</li>
                <li>Proven reliability</li>
                <li>Marine-grade quality</li>
                <li>Global support</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Integration</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Seamless compatibility</li>
                <li>Unified control</li>
                <li>System automation</li>
                <li>Future expandability</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Support</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Manufacturer warranty</li>
                <li>Technical expertise</li>
                <li>Parts availability</li>
                <li>System updates</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Partner Categories */}
            {brands.map((category, index) => (
              <section key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50 p-8 rounded-xl'}>
                <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                  {category.category}
                </h2>
                <div className="max-w-4xl mx-auto">
                  <p className="text-center text-lg mb-8 text-gray-700">{category.description}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {category.companies.map((company, companyIndex) => (
                      <div 
                        key={companyIndex}
                        className="flex items-center p-6 rounded-lg shadow-lg bg-white"
                      >
                        <div className="w-16 h-16 flex-shrink-0 bg-white rounded-lg flex items-center justify-center mr-4 border"
                             style={{ borderColor: customStyles.borderColor }}>
                          <img 
                            src={company.logo}
                            alt={`${company.name} brand logo`}
                            className="max-w-full max-h-full object-contain p-2"
                            onError={(e) => {
                              e.target.src = '/api/placeholder/50/50';
                              e.target.alt = 'Company logo placeholder';
                            }}
                          />
                        </div>
                        <div>
                          <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>{company.name}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ))}
          </div>
        </section>
      </article>
    </main>
  );
};

const ConsultationPage = () => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Schedule a Consultation | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Schedule a Consultation</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Let's discuss your vessel's technology needs</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Overview Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Expert Team</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Years of experience</li>
                <li>Technical expertise</li>
                <li>Industry knowledge</li>
                <li>Professional service</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Custom Solutions</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Tailored approach</li>
                <li>Vessel-specific design</li>
                <li>Flexible options</li>
                <li>Scalable systems</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Process</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Initial consultation</li>
                <li>Detailed proposal</li>
                <li>Clear timeline</li>
                <li>Ongoing support</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Consultation Benefits */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Why Schedule a Consultation
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Expert Guidance
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Benefit from our extensive experience in marine technology:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Professional needs assessment</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Technology recommendations</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Budget optimization</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Personalized Approach
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    We develop solutions tailored to your specific needs:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Custom system design</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Flexible implementation options</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Future-proof planning</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Booking Section */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Contact Us
              </h2>
              <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8">
                <div className="space-y-6 text-center">
                  <div>
                    <h3 className="text-xl font-semibold mb-3" style={{ color: customStyles.primary }}>Call Us</h3>
                    <a 
                      href="tel:+18662880028" 
                      className="text-lg hover:opacity-80 inline-block"
                      style={{ color: customStyles.primary }}
                    >
                      +1 866-288-0028
                    </a>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-3" style={{ color: customStyles.primary }}>Email Us</h3>
                    <a 
                      href="mailto:support@landandseaelectronics.com"
                      className="text-lg hover:opacity-80 break-words"
                      style={{ color: customStyles.primary }}
                    >
                      support@landandseaelectronics.com
                    </a>
                  </div>
                  <p className="text-gray-600 mt-6">
                    Our team is available Monday through Friday, 9:00 AM - 6:00 PM EST.
                    We strive to respond to all inquiries within 24 hours.
                  </p>
                </div>
              </div>
            </section>

            {/* What to Expect */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Consultation Process
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>1</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Discovery</h3>
                  <p className="text-sm text-gray-600">Initial discussion of your needs and goals</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>2</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Assessment</h3>
                  <p className="text-sm text-gray-600">Technical evaluation and requirements analysis</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>3</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Proposal</h3>
                  <p className="text-sm text-gray-600">Detailed solution and implementation plan</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>4</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Planning</h3>
                  <p className="text-sm text-gray-600">Timeline and resource coordination</p>
                </div>
              </div>
            </section>
          </div>
        </section>
      </article>
    </main>
  );
};

// After ConsultationPage Component and before App component
const ResidentialPage = ({ onNavigate }) => {
  // Update document title when component mounts
  React.useEffect(() => {
    document.title = "Residential Solutions | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Residential Solutions</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Smart home technology for luxury residences</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Home Automation</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Lighting control</li>
                <li>Climate management</li>
                <li>Shade automation</li>
                <li>Scene programming</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Entertainment</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Home theater design</li>
                <li>Multi-room audio</li>
                <li>Video distribution</li>
                <li>Gaming rooms</li>
              </ul>
            </div>
            
            <div className="p-6 rounded-lg shadow-lg text-center" style={{ backgroundColor: customStyles.lightBg }}>
              <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Security</h3>
              <ul className="space-y-3" style={{ color: customStyles.primary }}>
                <li>Surveillance systems</li>
                <li>Access control</li>
                <li>Network security</li>
                <li>Remote monitoring</li>
              </ul>
            </div>
          </div>

          <div className="space-y-16">
            {/* Smart Home Solutions */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Smart Home Integration
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Complete Control
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Seamlessly control your entire home environment:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Voice control integration</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Mobile device access</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Automated schedules</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Energy Management
                  </h3>
                  <p className="text-gray-700 leading-relaxed">
                    Optimize your home's energy consumption:
                  </p>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Smart thermostat control</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Automated lighting schedules</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Energy usage monitoring</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Entertainment Systems */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Premium Entertainment
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Home Theater</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• 4K/8K projection</li>
                    <li>• Surround sound</li>
                    <li>• Acoustic treatment</li>
                    <li>• Custom seating</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Multi-Room Audio</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• High-end speakers</li>
                    <li>• Streaming integration</li>
                    <li>• Zone control</li>
                    <li>• Outdoor audio</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4" style={{ color: customStyles.primary }}>Video Systems</h3>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Video distribution</li>
                    <li>• Content management</li>
                    <li>• Gaming integration</li>
                    <li>• Remote viewing</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Security Features */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Advanced Security
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Surveillance</h3>
                  <p className="text-gray-700">
                    Comprehensive monitoring solutions:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• 4K security cameras</li>
                    <li>• Night vision capabilities</li>
                    <li>• Motion detection</li>
                    <li>• Mobile alerts</li>
                  </ul>
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold" style={{ color: customStyles.primary }}>Access Control</h3>
                  <p className="text-gray-700">
                    Secure entry management:
                  </p>
                  <ul className="space-y-2 text-gray-700">
                    <li>• Smart locks</li>
                    <li>• Biometric systems</li>
                    <li>• Guest access</li>
                    <li>• Remote management</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Installation Process */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Installation Process
              </h2>
              <div className="grid md:grid-cols-4 gap-8">
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>1</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Assessment</h3>
                  <p className="text-sm text-gray-600">Detailed evaluation of your home and requirements</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>2</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Design</h3>
                  <p className="text-sm text-gray-600">Custom solution planning and system architecture</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>3</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Installation</h3>
                  <p className="text-sm text-gray-600">Professional installation and configuration</p>
                </div>
                <div className="text-center">
                  <div className="w-16 h-16 rounded-full bg-white mx-auto mb-4 flex items-center justify-center shadow-lg">
                    <span className="text-2xl font-bold" style={{ color: customStyles.primary }}>4</span>
                  </div>
                  <h3 className="font-semibold mb-2" style={{ color: customStyles.primary }}>Training</h3>
                  <p className="text-sm text-gray-600">System training and ongoing support</p>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Home Automation Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// Add new AVServicesPage component
const AVServicesPage = ({ onNavigate }) => {
  React.useEffect(() => {
    document.title = "Crestron Control Systems | Land and Sea Electronics";
  }, []);

  return (
    <main role="main">
      <article>
        <header className="text-center px-4 py-16 bg-white">
          <h1 className="text-5xl font-bold mb-6" style={{ color: customStyles.primary }}>Crestron Control Systems</h1>
          <p className="text-xl mb-16" style={{ color: customStyles.primary }}>Professional automation for lighting and shading systems</p>
        </header>

        <section className="max-w-7xl mx-auto px-4 py-8">
          {/* Hero Feature */}
          <div className="bg-white rounded-lg shadow-lg p-8 mb-16 text-center">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold mb-8" style={{ color: customStyles.primary }}>
                Complete Environmental Control
              </h2>
              <p className="text-gray-700 mb-8 text-lg">
                Take command of your environment with Crestron's industry-leading automation:
              </p>
              <ul className="space-y-4 text-gray-700 text-left max-w-xl mx-auto mb-8">
                <li className="flex items-start">
                  <span className="mr-2 text-xl" style={{ color: customStyles.primary }}>•</span>
                  <span>Integrated lighting and shade control</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-xl" style={{ color: customStyles.primary }}>•</span>
                  <span>Customizable scene programming</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-xl" style={{ color: customStyles.primary }}>•</span>
                  <span>Mobile device integration</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2 text-xl" style={{ color: customStyles.primary }}>•</span>
                  <span>Automated scheduling</span>
                </li>
              </ul>
              <button 
                onClick={() => onNavigate('consultation')}
                className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
                style={{ backgroundColor: customStyles.primary }}
              >
                Get Professional Control
              </button>
            </div>
          </div>

          <div className="space-y-16">
            {/* System Features */}
            <section>
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                System Features
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow-lg border" style={{ borderColor: customStyles.borderColor }}>
                  <h3 className="text-xl font-bold mb-4 text-center" style={{ color: customStyles.primary }}>Lighting Control</h3>
                  <ul className="space-y-3 mb-6 text-gray-700">
                    <li>• LED dimming systems</li>
                    <li>• Color temperature control</li>
                    <li>• Scene programming</li>
                    <li>• Motion sensors</li>
                  </ul>
                </div>

                <div className="p-6 bg-white rounded-lg shadow-lg border" style={{ borderColor: customStyles.borderColor }}>
                  <h3 className="text-xl font-bold mb-4 text-center" style={{ color: customStyles.primary }}>Shade Control</h3>
                  <ul className="space-y-3 mb-6 text-gray-700">
                    <li>• Motorized blinds</li>
                    <li>• Natural light optimization</li>
                    <li>• UV protection</li>
                    <li>• Privacy control</li>
                  </ul>
                </div>

                <div className="p-6 bg-white rounded-lg shadow-lg border" style={{ borderColor: customStyles.borderColor }}>
                  <h3 className="text-xl font-bold mb-4 text-center" style={{ color: customStyles.primary }}>Automation</h3>
                  <ul className="space-y-3 mb-6 text-gray-700">
                    <li>• Time-based events</li>
                    <li>• Occupancy sensing</li>
                    <li>• Weather integration</li>
                    <li>• Energy management</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Control Options */}
            <section className="bg-gray-50 p-8 rounded-xl">
              <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: customStyles.primary }}>
                Control Options
              </h2>
              <div className="grid md:grid-cols-2 gap-12">
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Touch Panels
                  </h3>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Wall-mounted displays</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Wireless touchscreens</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Custom interface design</span>
                    </li>
                  </ul>
                </div>
                <div className="space-y-6">
                  <h3 className="text-2xl font-semibold" style={{ color: customStyles.primary }}>
                    Mobile Control
                  </h3>
                  <ul className="space-y-3 text-gray-700">
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>iOS and Android apps</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Remote access</span>
                    </li>
                    <li className="flex items-start">
                      <span className="mr-2">•</span>
                      <span>Multi-user support</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="text-center mt-16">
            <h3 className="text-2xl font-bold mb-4" style={{ color: customStyles.primary }}>
              Ready to Upgrade Your Control Systems?
            </h3>
            <p className="text-gray-700 mb-8 max-w-2xl mx-auto">
              Experience the power of professional Crestron automation for your lighting and shading systems.
            </p>
            <button 
              onClick={() => onNavigate('consultation')}
              className="px-8 py-3 rounded-lg text-white hover:opacity-80 transition-colors"
              style={{ backgroundColor: customStyles.primary }}
            >
              Schedule Consultation
            </button>
          </div>
        </section>
      </article>
    </main>
  );
};

// Update App component with ConsultationPage route
const App = () => {
  const [currentPage, setCurrentPage] = useState('home');

  // Add validPages array
  const validPages = [
    'home', 'network', 'audio-video', 'entertainment', 
    'security', 'blinds', 'lighting', 'commercial-av', 
    'diy', 'refit', 'portfolio', 'specifications', 
    'consultation', 'residential', 'av-services'  // Add av-services
  ];

  const handleNavigate = (page) => {
    // Check if page exists
    if (!validPages.includes(page)) {
      setCurrentPage('404');
    } else {
      setCurrentPage(page);
    }
    window.scrollTo(0, 0);
    
    // Track page view
    trackPageView(page);
    
    // Update meta description based on current page
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      switch(page) {
        case 'home':
          metaDescription.setAttribute("content", "Land and Sea Electronics specializes in marine technology solutions including audio/video systems, networking, entertainment, security, blinds, and lighting control for luxury vessels.");
          break;
        case 'network':
          metaDescription.setAttribute("content", "Enterprise-grade marine networking solutions with VSAT, 4G/5G connectivity, and professional WiFi systems for luxury vessels.");
          break;
        // Add cases for other pages...
        default:
          metaDescription.setAttribute("content", "Premium marine technology solutions for luxury vessels by Land and Sea Electronics.");
      }
    }
  };

  return (
    <div className="min-h-screen">
      <header role="banner">
        <Navigation onNavigate={handleNavigate} />
      </header>
      
      {currentPage === '404' ? (
        <NotFoundPage onNavigate={handleNavigate} />
      ) : (
        <>
          {currentPage === 'home' && <HomePage onNavigate={handleNavigate} />}
          {currentPage === 'network' && <NetworkPage onNavigate={handleNavigate} />}
          {currentPage === 'audio-video' && <AudioVideoPage onNavigate={handleNavigate} />}
          {currentPage === 'entertainment' && <EntertainmentPage onNavigate={handleNavigate} />}
          {currentPage === 'security' && <SecurityPage onNavigate={handleNavigate} />}
          {currentPage === 'blinds' && <BlindsPage onNavigate={handleNavigate} />}
          {currentPage === 'lighting' && <LightingPage onNavigate={handleNavigate} />}
          {currentPage === 'commercial-av' && <CommercialAVPage onNavigate={handleNavigate} />}
          {currentPage === 'diy' && <DIYPage onNavigate={handleNavigate} />}
          {currentPage === 'refit' && <RefitPage onNavigate={handleNavigate} />}
          {currentPage === 'portfolio' && <PortfolioPage />}
          {currentPage === 'specifications' && <SpecificationsPage />}
          {currentPage === 'consultation' && <ConsultationPage />}
          {currentPage === 'residential' && <ResidentialPage onNavigate={handleNavigate} />}
          {currentPage === 'av-services' && <AVServicesPage onNavigate={handleNavigate} />}
        </>
      )}
      
      <footer role="contentinfo" className="bg-white border-t mt-16" style={{ borderColor: customStyles.borderColor }}>
        <div className="max-w-7xl mx-auto py-8 px-4">
          <p className="text-center" style={{ color: customStyles.primary }}>
            © {new Date().getFullYear()} Land and Sea Electronics. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default App;